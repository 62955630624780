import React from "react"
import { LazyLoadImage } from 'react-lazy-load-image-component';

const SellYourHomeCard = ({ image, title, description, link, reverse }) => {
  return (
    <div className="sell-your-home-card__section">
      {reverse ? (
        <>
          <div className="sell-your-home-card__small-card-reverse">
            <h1 className="sell-your-home-card__small-card-reverse__title h1-header">
              {title}
            </h1>
            <p className="sell-your-home-card__small-card-reverse__description body1">
              {description}
            </p>
          </div>{" "}
          <LazyLoadImage
            className="sell-your-home-card__large-card-reverse"
            src={image}
            alt={"Sell Your Home"}
          />
        </>
      ) : (
        <>
          <LazyLoadImage
            className="sell-your-home-card__large-card"
            src={image}
            alt={"Sell Your Home"}
          />
          <div className="sell-your-home-card__small-card">
            <h1 className="sell-your-home-card__small-card__title h1-header">
              {title}
            </h1>
            <p className="sell-your-home-card__small-card__description body1">
              {description}
            </p>
          </div>
        </>
      )}
    </div>
  )
}

export default SellYourHomeCard
