import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SellYourHomeCard from "../components/sell-your-home-card"
import StandardGallery from "../components/standard-gallery"
import parse from "html-react-parser"

const SellYourHome = ({ data, location }) => {
  const currentListing = data.allListingResidential.nodes.filter(
    p => p.ListOfficeMlsId === process.env.OFFICE_MLS_ID
  )

  const {
    header,
    sectionOneDescription,
    sectionOneImage,
    sectionOneTitle,
    sectionThreeDescription,
    sectionThreeTitle,
    sectionTwoDescription,
    sectionTwoImage,
    sectionTwoTitle,
    sellingWithUsDescription,
    sellingWithUsTitle,
    featuredPropertiesTitle
  } = data.sellyourhome

  return (
    <Layout>
      <Seo title={"Sell Your Home"} location={location} />
      <img className="cha-sell-your-home-page__header-img" src={header.url} />
      <div className="cha-sell-your-home-page__container">
        <h1 className="cha-sell-your-home-page__container__title h1-header">
          {sellingWithUsTitle}
        </h1>
        <p className="cha-sell-your-home-page__container__description body1">
          {parse(sellingWithUsDescription)}
        </p>
      </div>

      <div className="sell-your-home-card__container">
        <div className="sell-your-home-card__container containers">
          <SellYourHomeCard
            image={sectionOneImage.url}
            title={sectionOneTitle}
            description={parse(sectionOneDescription)}
          />
          <SellYourHomeCard
            image={sectionTwoImage.url}
            title={sectionTwoTitle}
            description={parse(sectionTwoDescription)}
            reverse={true}
          />
        </div>

      </div>

      <div className="cha-sell-your-home-page__note">
        <span className="cha-sell-your-home-page__note__title subhead">
          {sectionThreeTitle}
        </span>
        <p className="cha-sell-your-home-page__note__description body1">
          {parse(sectionThreeDescription)}
        </p>
      </div>

      <div className="standard-gallery-container">
        <h1 className=" h1-header">
          {featuredPropertiesTitle}
        </h1>
        <StandardGallery posts={currentListing} />
      </div>
    </Layout>
  )
}

export default SellYourHome

export const query = graphql`
  query MyQueryListingResidential {
    allListingResidential(filter: { StandardStatus: { ne: "Closed" } }) {
      nodes {
        ListPrice
        BedroomsTotal
        BathroomsTotalInteger
        UnparsedAddress
        PrefferedPhoto
        ListOfficeMlsId
        ListingId
        MLSAreaMajor
        MLSAreaMinor
        parsedAddress
        SubdivisionName
        LivingArea
        ArchitecturalStyle
        LotSizeAcres
        PropertyCondition
        StandardStatus
        path
      }
    }
    sellyourhome {
      sectionOneDescription
      sectionOneImage {
        url
      }
      sectionOneTitle
      sectionThreeDescription
      sectionThreeTitle
      sectionTwoDescription
      sectionTwoImage {
        url
      }
      sectionTwoTitle
      sellingWithUsDescription
      sellingWithUsTitle
      featuredPropertiesTitle
      header {
        url
      }
    }
  }
`
